/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/roboto-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local('Roboto Italic'), local('Roboto-Italic'),
    url('../fonts/roboto-v15-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/roboto-v15-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/roboto-v15-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/roboto-v15-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/roboto-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/roboto-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


html, body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

md-toolbar h1 {
    margin: auto;
}

md-list .md-button {
    color: inherit;
    font-weight: 500;
    text-align: left;
    width: 100%;
}

md-list .md-button.selected {
    color: #03a9f4;
}

md-sidenav md-list {
    padding: 0px 8px 8px 0px;
}

.checkout-view input {
    margin-left: 35px;
}

.checkout-view [ng-messages] {
    margin-left: 35px;
}

.host-page {
    background: white;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    overflow-y: hidden;
}

.host-page .phone-number {
    color: white;
    padding: 5px;
    font-size: .80em;
}

.host-page .top-header {
    background-color: black;
}

.host-page .restaurant-title {
    color: white;
}

.host-page .top-menu {
    font-size: .80em;
}

.host-page .top-menu .menu-item {
    color: white;
    margin: 0 7px 0 7px;
}

.host-page .hide-indicator {
    display: none;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: .9;
    transition: opacity .2s;
    background-color: #656565;
    z-index: 100000
}

.modal-container.in {
    opacity: .2;
}

.modal-container .message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #cccaca;
    padding: 30px;
    border-radius: 10px;
}

.modal-container .fail-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e1d1c2;
    padding: 30px;
    border-radius: 10px;
}

.modal-container .order-placed-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #cccaca;
    padding: 30px;
    border-radius: 10px;
}

